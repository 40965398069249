import React, { useEffect, useState } from "react";
import api from "../../service/api";
import { Button, Input, Modal, Space, Table } from "antd";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import * as S from "./styles";

import "antd/dist/antd.css";

import { useSearch } from "../../context/SearchContext";

export type DataTable = {
  descricao: string;
  id: number;
  linkYouTube: string;
  ordemDasPerguntas: number;
  titulo: string;
  slugTitulo: string;
};

export type Table = {
  setBottom: Function;
};

export default function TableAnt({ setBottom }: Table) {
  const [data, setData] = useState<DataTable[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [openModalReprove, setOpenModalReprove] = useState(false);
  const [recarregar, setRecarregar] = useState(false);
  const [dataJulgado, setDataJulgado] = useState<{
    id: String;
    aprovado: boolean;
    message?: string;
  }>({} as { id: String; aprovado: boolean; message?: string });
  const user = JSON.parse(localStorage.getItem("user"));
  const history = useHistory();

  const { search } = useSearch();

  const columns = [
    {
      title: "Nome",
      dataIndex: "titulo",
      key: "titulo",
    },
    {
      title: "Autor",
      dataIndex: "autor",
      key: "autor",
    },
    {
      title: "Status",
      dataIndex: "statusDesc",
      key: "statusDesc",
    },
    {
      title: "Visualizar",
      dataIndex: "id",
      key: "id",
      render: (id) => (
        <Space size="middle">
          <a onClick={() => editQuestion(id)}>Visualizar</a>
        </Space>
      ),
    },
    {
      title: "Ação",
      dataIndex: "id",
      key: "id",
      width: "20%",
      render: (id) => (
        <Space size="middle">
          <a onClick={() => verifyModalAprove(id, true)}>Aprovar</a>
          <a onClick={() => verifyModalReprove(id, false)}>Reprovar</a>
        </Space>
      ),
    },
  ];

  const editQuestion = (id) => {
    history.push(
      `/visualizar/simulado/${data?.find((e) => e.id == id)?.slugTitulo}`,
      {
        uuid: id,
      }
    );
  };

  const verifyModalAprove = (id, aprovado) => {
    setDataJulgado({ id: id, aprovado: aprovado });
    setOpenModal(true);
  };

  const verifyModalReprove = (id, aprovado) => {
    setDataJulgado({ id: id, aprovado: aprovado });
    setOpenModalReprove(true);
  };

  async function aprovar() {
    if (user) {
      let dataRequest = {
        idSimulado: dataJulgado.id,
        aprovado: dataJulgado.aprovado,
        observacao: "",
      };
      setIsLoading(true);
      await api
        .post(`Simulado/alterar-status-simulado`, dataRequest)
        .then(function (response) {
          if (response) {
            toast.success("Simulado aprovado com sucesso!");
            setIsLoading(false);
            setRecarregar(!recarregar);
            window.location.reload();
            onCancel();
          }
        })
        .catch(function (error) {
          toast.error(`Um erro inesperado aconteceu`);
          setIsLoading(false);
        });
    }
  }

  async function reprovar() {
    if (user) {
      let dataRequest = {
        idSimulado: dataJulgado.id,
        aprovado: dataJulgado.aprovado,
        observacao: dataJulgado.message,
      };
      setIsLoading(true);
      await api
        .post(`Simulado/alterar-status-simulado`, dataRequest)
        .then(function (response) {
          if (response) {
            toast.success("Simulado reprovado com sucesso!");
            setIsLoading(false);
            setRecarregar(!recarregar);
            window.location.reload();
            onCancel();
          }
        })
        .catch(function (error) {
          toast.error(`Um erro inesperado aconteceu`);
          setIsLoading(false);
        });
    }
  }

  useEffect(() => {
    const getSimulateds = async () => {
      if (user == null) {
        toast.error(`Você precisa estar logado`);
        setTimeout(() => {
          window.location.href = "/";
        }, 5000);
      }

      await api
        .get(`Simulado/ObterTodosSimuladosPendentes`, {
          params: { filter: search },
        })
        .then(function (response) {
          if (response.data.length > 0) {
            setData(response.data);
          }
          setIsLoading(false);
        })
        .catch(function (error) {
          setIsLoading(false);
          toast.error(`Um erro inesperado aconteceu`);
        });
    };

    getSimulateds();
  }, [search, recarregar]);

  const motivoVazio = () => {
    toast.error("Precisa dizer o motivo");
  };

  const onCancel = () => {
    setOpenModalReprove(false);
    setOpenModal(false);
    setDataJulgado({} as { id: string; aprovado: boolean });
  };

  return (
    <>
      <S.DivTable>
        <Table
          pagination={{
            pageSizeOptions: ["10", "20", "30"],
          }}
          loading={isLoading}
          columns={columns}
          dataSource={data}
        />
      </S.DivTable>
      <Modal
        title="Aprovar"
        visible={openModal}
        onCancel={() => onCancel()}
        footer={[
          <>
            <Button
              key="submit"
              type="default"
              onClick={() => setOpenModal(false)}
            >
              Não
            </Button>
            <Button key="submit" type="primary" onClick={() => aprovar()}>
              Sim
            </Button>
          </>,
        ]}
      >
        <p>Tem certeza de que deseja aprovar?</p>
      </Modal>
      <Modal
        title="Reprovar"
        visible={openModalReprove}
        onCancel={() => onCancel()}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => (dataJulgado.message ? reprovar() : motivoVazio())}
          >
            Reprovar
          </Button>,
        ]}
      >
        <p>Tem certeza de que deseja reprovar?</p>
        <Input.TextArea
          onChange={(e) =>
            setDataJulgado((dataJulgado) => ({
              ...dataJulgado,
              message: e.target.value,
            }))
          }
          placeholder="Descreva o motivo"
          maxLength={100}
        />
      </Modal>
    </>
  );
}
