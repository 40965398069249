import React, { useEffect, useState } from "react";
import { Button, Divider } from "antd";
import { toast } from "react-toastify";
import { useHistory, useLocation } from "react-router-dom";
import { Input } from "antd";

import TableVizualizeQuestions from "../../components/TableVizualizeQuestions";

import * as S from "./styles";

import api from "../../service/api";

import MetaTags from "react-meta-tags";
import { Rating } from "react-simple-star-rating";

export type Uuid = {
  uuidSimulado?: string;
};

export type Resposta = {
  id: string;
  idPergunta: string;
  descricao: string;
  correta: boolean;
};

export type Perguntas = {
  comentarioFinal: string;
  descricao: string;
  id: string;
  multiplaEscolha: false;
  respostas: Resposta[];
  linkImagemCapaSimulado?: any;
  filenameImage: any;
};

type NewType = {
  id: string;
  tempoPorProva: any;
  author: string;
  descricao: string;
  linkYouTube: string;
  titulo: string;
  ordemDasPerguntas: number;
  linkImagemCapaSimulado: string;
  perguntas: Perguntas[];
  nota: number;
};

export type FormCreatedSimulated = NewType;

export default function VisualizeSimulated() {
  const { TextArea } = Input;
  const [simulated, setSimulated] = useState<FormCreatedSimulated>();
  const [votedQuantity, setVotedQuantity] = useState<number>(0);
  const [quantidadeDeVotos, setQuantidadeDeVotos] = useState<number>(0);

  const { state } = useLocation<any>();
  const user = JSON.parse(localStorage.getItem("user"));
  const history = useHistory();

  function DeleteSimulated(id) {
    api
      .delete(`Simulado/${id}`, {})
      .then(function () {
        toast.success("Simulado Deletado com sucesso ");
        history.push("/");
      })
      .catch(function (error) {
        toast.error(`Um erro inesperado aconteceu`);
      });
  }

  function UpdateSimulated(id) {
    history.push(`/editar/simulado/${id}`);
  }

  function goToExecucaoSimulado() {
    history.replace(`/simulado/${simulated?.id}`);
  }

  useEffect(() => {
    function getSimulatedById() {
      const { uuid } = state;

      api
        .get(`Simulado/semOrdenacao/${uuid}`)
        .then(function (response) {
          setSimulated(response.data);
          setQuantidadeDeVotos(response.data.quantidadeVotos);
        })
        .catch(function (error) {
          toast.error(`Um erro inesperado aconteceu`);
        });

      api
        .post(`Simulado/adicionar-visualizacao/${uuid}`)
        .then(function (response) {})
        .catch(function (error) {
          toast.error(`Um erro inesperado aconteceu`);
        });
    }

    function getSimulatedByName() {
      api
        .get(
          `Simulado/semOrdenacao/byTitulo/${window.location.pathname.replace(
            "/visualizar/simulado/",
            ""
          )}`
        )
        .then(function (response) {
          setSimulated(response.data);
          setQuantidadeDeVotos(response.data.quantidadeVotos);
        })
        .catch(function (error) {
          toast.error(`Um erro inesperado aconteceu`);
        });

      api
        .post(`Simulado/adicionar-visualizacao/${simulated?.id}`)
        .then(function (response) {})
        .catch(function (error) {
          toast.error(`Um erro inesperado aconteceu`);
        });
    }

    if (state?.uuid) {
      getSimulatedById();
    } else if (window.location?.pathname) {
      getSimulatedByName();
    }
  }, [state]);

  const vote = (nota) => {
    api
      .post(`Simulado/adicionar-visualizacao/${simulated?.id}/${nota}`)
      .then(function (response) {
        toast.success("Voto computado com sucesso");
        setQuantidadeDeVotos(quantidadeDeVotos + 1);
      })
      .catch(function (error) {
        toast.error(`Um erro inesperado aconteceu`);
      });
  };

  const handleRating = (e) => {
    if (votedQuantity >= 1) {
      toast.error("Quantidade de votos excedidos");
      return;
    }
    vote(e);
    setVotedQuantity(votedQuantity + 1);
  };
  return (
    <>
      {simulated && (
        <>
          <MetaTags>
            <title>{simulated.titulo}</title>
            <meta name="description" content={simulated.descricao} />
            <meta property="og:title" content={simulated.titulo} />
            <meta property="og:description" content={simulated.descricao} />
            {simulated.linkImagemCapaSimulado && (
              <meta
                property="og:image"
                content={simulated.linkImagemCapaSimulado}
              />
            )}
          </MetaTags>
          <S.Title>{simulated?.titulo}</S.Title>
          <S.ContainerVideoOrImage>
            {simulated.linkYouTube && (
              <iframe
                height="315"
                src={simulated.linkYouTube}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            )}
            {simulated.linkImagemCapaSimulado && (
              <img src={simulated.linkImagemCapaSimulado} alt="logo Simulado" />
            )}
          </S.ContainerVideoOrImage>
          <Divider />
          <S.ContainerDescription>
            <TextArea
              rows={4}
              disabled={true}
              style={{ color: "#373737" }}
              defaultValue={simulated?.descricao}
            />
          </S.ContainerDescription>
          <S.ContainerButton>
            <div>
              <Button
                type="primary"
                style={{
                  backgroundColor: "#38B000",
                  border: "none",
                }}
                size="large"
                onClick={() => {
                  goToExecucaoSimulado();
                }}
              >
                Iniciar Simulado
              </Button>

              {user?.tipoUser == 1 && (
                <div style={{ marginTop: 15 }}>
                  <Button
                    onClick={() => UpdateSimulated(simulated.id)}
                    type="primary"
                    style={{ width: "77px" }}
                  >
                    Editar
                  </Button>
                  <Button
                    onClick={() => DeleteSimulated(simulated.id)}
                    type="primary"
                    style={{ width: "77px", marginLeft: 10 }}
                    danger
                  >
                    Deletar
                  </Button>
                </div>
              )}
            </div>
            <S.ContainerRating>
              <Rating
                size={25}
                onClick={handleRating}
                initialValue={simulated.nota}
              />
              <S.TextRatingVotes>{`(${quantidadeDeVotos})`}</S.TextRatingVotes>
            </S.ContainerRating>
          </S.ContainerButton>

          <S.ContainerTableQuestions>
            <TableVizualizeQuestions perguntas={simulated.perguntas} />
          </S.ContainerTableQuestions>
        </>
      )}
    </>
  );
}
