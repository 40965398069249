import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import ptBR from 'antd/lib/locale/pt_BR'
import GlobalStyles from '../src/styles/global'
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from 'antd'
import { ToastContainer } from 'react-toastify';
import { AuthProvider } from './hooks/auth'
import 'antd/dist/antd.css'
import 'react-toastify/dist/ReactToastify.css';
import "./index.css"
import ICookie from './components/Cookie';

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <ConfigProvider locale={ptBR}>
        <App />
        <ICookie />
        <GlobalStyles />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          />
      </ConfigProvider>
      </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
