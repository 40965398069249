import React from "react";
import * as S from "./styles";

export type FooterProps = {
  bottom: boolean;
};

export default function Footer({ bottom }: FooterProps) {
  return (
    <S.Footer>
      <S.Wrapper>
        <S.ContainerLogo>
          <S.ContainerQuemSomos>
            <S.Title
              target="_blank"
              href="https://bynem.com.br/blog/quem-somos/"
            >
              Quem Somos
            </S.Title>
          </S.ContainerQuemSomos>
          <S.ContainerContato>
            <S.Title target="_blank" href="https://bynem.com.br/blog/contato/">
              Contato
            </S.Title>
            <S.SubTitle></S.SubTitle>
          </S.ContainerContato>
          <S.ContainerPoliticaDePrivacidade>
            <S.Title
              target="_blank"
              href="https://bynem.com.br/blog/politica-de-privacidade/"
            >
              Politica de Privacidade
            </S.Title>
          </S.ContainerPoliticaDePrivacidade>
        </S.ContainerLogo>
        <S.ContainerRedeSociais>
          <S.Link
            target="_blank"
            href="https://www.facebook.com/BynemSimulados"
          >
            <S.FacebookIconc />
          </S.Link>
          <S.Link target="_blank" href="https://twitter.com/BynemSimulados">
            <S.TwitterIconc />
          </S.Link>
          <S.Link
            target="_blank"
            href="https://www.instagram.com/bynem.com.br/"
          >
            <S.InstagramIconc />
          </S.Link>
          <S.Link
            target="_blank"
            href="https://www.linkedin.com/company/bynem/"
          >
            <S.LinkedInIconc />
          </S.Link>
        </S.ContainerRedeSociais>
      </S.Wrapper>
    </S.Footer>
  );
}
