import React from 'react';

import Table from '../../components/Table'

import MetaTags from 'react-meta-tags';

const Home = () => (
    <>
        <MetaTags>
            <title>Bynem Simulados</title>
            <meta  name="description" content="Bynem" />
        </MetaTags>
        <Table setBottom={() => { }} />
    </>
    
)

export default Home;
